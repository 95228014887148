import animalsDB from "../database/animalsDB";
import groupsDB from "../database/groupsDB";
import {AnimalTypes} from "../constans/animalTypes";
import {getTimeToAddPigletToGroup, getTimeToAddPorkerToGroup} from "./SettingsUtils";
import moment from "moment";
import {isBetween} from "./MathUtils";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {memoize, pick} from "lodash";
import {ReactLocalStorage} from "./ReactLocalStorage";
import GroupMortalityQuery from "./queries/GroupMortality.query";

const GROUP_ANIMAL_TYPES = `
SELECT 
  SUM(
    CASE WHEN key = 'weanedInsertedCnt' THEN [value] ELSE 0 END
  ) > 0 as hasWeaners, 
  SUM(
    CASE WHEN key = 'giltInsertedCnt' THEN [value] ELSE 0 END
  ) > 0 as hasGilts, 
  SUM(
    CASE WHEN key = 'finisherInsertedCnt' 
    OR key = 'pigletReclassifyToPorker' THEN [value] ELSE 0 END
  ) > 0 as hasFinisher 
FROM 
  ParamsTable 
WHERE 
  group_nr = ? 
  AND key IN (
    'weanedInsertedCnt', 'giltInsertedCnt', 
    'finisherInsertedCnt', 'pigletReclassifyToPorker'
  )
`;

const GROUP_FATTENING = `
SELECT 
  FIRST_NOT_NULL(
    CASE WHEN key = 'weanedPigPigletHouseAge' THEN [value] END
  ) as weanersAge, 
  FIRST_NOT_NULL(
    CASE WHEN key = 'finisherPigPorkHouseAge' THEN [value] END
  ) as finisherAge 
FROM 
  ParamsTable 
WHERE 
  group_nr = ? 
  AND key IN (
    'weanedPigPigletHouseAge', 'finisherPigPorkHouseAge'
  )
`;

function groupsSortedByInsertion() {
    return `
        SELECT
            group_nr, LAST(dataEv) as dataEv
        FROM
            ParamsTable
        WHERE   key IN (
        'finisherInsertedCnt', 'giltInsertedCnt',
        'weanedInsertedCnt'
        )
        GROUP BY
            group_nr
        ORDER BY
            dataEv DESC
    `;
}

function createLastXInserted(limit) {
    return `
        SELECT 
            group_nr, LAST(dataEv) as dataEv
        FROM 
            ParamsTable 
        WHERE 
            key IN (
                'finisherInsertedCnt', 'giltInsertedCnt', 
                'weanedInsertedCnt'
            ) 
        GROUP BY 
            group_nr 
        ORDER BY 
            dataEv DESC 
        LIMIT 
            ${limit}
    `;
}

function createLastXClosed(limit) {
    return `
        SELECT
            group_nr, LAST([value]) as value
        FROM
            ParamsTable
        WHERE
            key = 'groupCloseTime'
        GROUP BY
            group_nr
        ORDER BY
            value DESC
        LIMIT
            ${limit}
    `;
}

function getGroupForAnimals(animals) {
    for (let animal of animals) {
        const groups = groupsDB.getGroupWithAnimal(animal.AnmID, animal.FarmID);
        if (groups.length > 0) return groups[0];
    }
    return null;
}

export function checkGroupInsertionTimeRange(group, InsertionTime, maxDaysRange = 0) {
    let minTime = null;
    let maxTime = null;
    if (Array.isArray(group.AnmIDs)) {
        for (let AnmID of group.AnmIDs) {
            const animal = animalsDB.getAnimalById(AnmID, {joinEvents: false});
            if (animal?.DtaInTime) {
                if (minTime === null || (animal.DtaInTime < minTime)) {
                    minTime = animal.DtaInTime;
                }
                if (maxTime === null || (animal.DtaInTime > maxTime)) {
                    maxTime = animal.DtaInTime;
                }
            }
        }
    }
    if (minTime === null) minTime = group.DtaModTime;
    if (maxTime === null) maxTime = group.DtaModTime;
    const _minTime = +moment(maxTime).startOf("day").subtract(maxDaysRange, "days");
    const _maxTime = +moment(minTime).endOf("day").add(maxDaysRange, "days");
    console.log("checkGroupInsertionTimeRange insertion %s min %s max %s (days in range %s)", InsertionTime, _minTime, _maxTime, maxDaysRange);
    return isBetween(InsertionTime, _minTime, _maxTime);
}

export function checkGroupTimeForLocation(locationID, time = +new Date()) {
    let animals = animalsDB.getAllAnimalsByPlcmntID(locationID, {joinEvents: false});
    let group = getGroupForAnimals(animals);
    if (group) {
        const daysToAdd = AnimalTypes.PORKER === animals[0].AnimalKind ? getTimeToAddPorkerToGroup() : getTimeToAddPigletToGroup();
        return checkGroupInsertionTimeRange(group, time, daysToAdd);
    }
    return true;
}

export function getAnimalsNumber(group) {
    let sum = 0;
    if (!group.AnmIDs.length) return sum;
    for (let id of group.AnmIDs) {
        let animal = animalsDB.getAnimalById(id);
        if (animal) sum += animal.AnmCnt;
    }
    return sum;
}

export function setBirthDate(group, timestamp) {
    group.GroupBirthTime = timestamp;
}

const _DEBUG = ReactLocalStorage?.get("debug_groups_pls", false) ?? false;

const debug = (...args) => _DEBUG ? console.log(...args) : null;

export const isSubgroup = (animal) => {
    const individualTypes = [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW, AnimalTypes.BOAR];
    if (individualTypes.includes(animal.AnimalKind)) return false;
    return !(animal.RFID || animal.Tagged);
};

const isAnimalEligibleForTimespan = (AnmID) => {
    const animal = animalsDB.getAnimalById(AnmID, {joinEvents: false});
    if (!animal) return false;
    return !isSubgroup(animal);
}

/**
 * @param allGroupEvents
 * @param currentGroupId
 * @param opts
 * @param opts.isAnimalSupported {function}
 * @return {{}}
 */
export function getAnimalTimespanInGroupByAllEvents(allGroupEvents, currentGroupId, opts = {}) {
    // trzyma przeniesienia pogrupowane po zwierzętach
    const transfersByAnmID = {};
    // loki calls are heavy due to serialization - so we use memoization here
    const isAnimalSupported = opts?.isAnimalSupported ?? memoize(isAnimalEligibleForTimespan);
    for (let event of allGroupEvents) {
        if (event.EvCode !== EventTypes.TRANSFER) continue;
        if (!transfersByAnmID[event.AnmID]) {
            transfersByAnmID[event.AnmID] = [];
        }
        if (!isAnimalSupported(event.AnmID)) continue;
        transfersByAnmID[event.AnmID].push(event);
    }
    let animalTimespan = {};
    for (const AnmID in transfersByAnmID) {
        debug("AnmID=>%s", AnmID);
        debug(transfersByAnmID[AnmID].map(prettyPrintTransfer).join("\n"));
        // przyjmujemy, że jest w "starej" grupie z pierwszego eventu przeniesienia
        const firstMeaningfulTransfer = transfersByAnmID[AnmID].find((ev) => ev.EvData.OldGroupID);
        // #10545: jeśli nie mamy `OldGroupID` to przyjmujemy, że znajduje się w wybranej grupie
        let startingGroup = currentGroupId;
        if (firstMeaningfulTransfer?.EvData?.OldGroupID) {
            const {OldGroupID, NewGroupID, GroupTransfer, TransferTo} = firstMeaningfulTransfer.EvData;
            startingGroup = OldGroupID;
            // #10567: jeśli ktoś ma włączone łączenie grup to transfer z `TransferTo`
            // merguje zwierzęta i należy olać taki event
            if (
                (TransferTo === AnmID) &&
                [NewGroupID, GroupTransfer].includes(currentGroupId)
            ) {
                startingGroup = currentGroupId;
                debug("firstMeaningfulTransfer (A)");
            }
        }
        // nie bierzemy ich pod uwagę przy eventach przeniesień
        if (firstMeaningfulTransfer?.EvData?.OldGroupID && firstMeaningfulTransfer.EvData.TransferTo !== AnmID) {
            startingGroup = firstMeaningfulTransfer?.EvData?.OldGroupID;
            debug("firstMeaningfulTransfer (B)");
        }
        const ts = [{
            time: 0,
            group: startingGroup
        }];
        debug("starting group %s", startingGroup);
        // uzupełnij o dane z przeniesień
        for (const transfer of transfersByAnmID[AnmID]) {
            const newGroupID = transfer.EvData?.NewGroupID ?? transfer.EvData?.GroupTransfer;
            if (!newGroupID) continue;
            // #10567: jeśli mamy `TransferTo` to znaczy, że zwierzęta zostały "zmergowane" do innej grupy (zwierzęcia)
            if (transfer?.EvData?.TransferTo) continue;
            debug(newGroupID, new Date(transfer.EvTime).toISOString());
            ts.push({
                time: transfer.EvTime,
                group: newGroupID
            });
        }
        // jako ostatnią grupę ustaw miejsce ostatniego przeniesienia
        ts.push({
            time: Math.max(21474836470000, new Date().getTime()),
            group: ts[ts.length - 1].group
        });
        // tymczasowy przedział czasu
        let tmpRange = null;
        // przedziały czasu wyszukiwanej grupy
        let ranges = [];
        for (const {time, group} of ts) {
            if (group === currentGroupId) {
                if (tmpRange === null) {
                    tmpRange = [time, time];
                }
                tmpRange[1] = time;
            } else {
                if (tmpRange) {
                    tmpRange[1] = time;
                    ranges.push(tmpRange);
                    tmpRange = null;
                }
            }
        }
        // zamknij zaczęty przedział
        if (tmpRange) {
            ranges.push(tmpRange);
        }
        animalTimespan[AnmID] = ranges;
    }
    if (_DEBUG) {
        prettyPrintAnimalTimespan(animalTimespan);
    }
    return animalTimespan;
}

export const prettyPrintAnimalTimespan = (animalTimespan) => {
    console.group("GROUP_TIMESPAN");
    const DATE_FORMAT = "DD.MM.YYYY (HH:mm)";
    for (let AnmID in animalTimespan) {
        console.log("AnmID=>  %s:", AnmID);
        for (let [start, end] of animalTimespan[AnmID]) {
            console.log("     %s - %s", moment(start).format(DATE_FORMAT), moment(end).format(DATE_FORMAT));
        }
    }
    console.groupEnd();
};


const prettyPrintTransfer = (event) => {
    return `${new Date(event.EvTime).toISOString()}   ${event.AnmCnt}   : ${JSON.stringify(pick(event.EvData, ["TransferTo", "OldGroupID", "NewGroupID", "GroupTransfer"]))}`;
};

export function getGroupAnimalTypes(queryCaller, anmGrp) {
    return queryCaller(GROUP_ANIMAL_TYPES, [anmGrp])?.[0] || {hasWeaners: false, hasGilts: false, hasFinisher: false};
}

export function getGroupFatteningTimes(queryCaller, anmGrp) {
    return queryCaller(GROUP_FATTENING, [anmGrp])?.[0] || {weanersAge: null, finisherAge: null};
}

export function getGroupMortalityChartData(queryCaller, anmGrp, endTimestamp = null) {
    try {
        return queryCaller(GroupMortalityQuery, [anmGrp, anmGrp, anmGrp, anmGrp, anmGrp, endTimestamp]);
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function getLastInsertedGroups(queryCaller, limit = 1) {
    return queryCaller(createLastXInserted(limit)); // alasql nie obsluguje wstawiania ? w limit
}

export function getLastClosedGroups(queryCaller, limit = 1) {
    return queryCaller(createLastXClosed(limit));
}

export function getGroupsSortedByInsertion(queryCaller) {
    return queryCaller(groupsSortedByInsertion()); // alasql nie obsluguje wstawiania ? w limit
}