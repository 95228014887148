import store from "../store/store";
import {
    createParturitionNotificationFailure,
    createParturitionNotificationSuccess,
} from "../api/events/Parturition";
import {
    createInsertionNotificationFailure,
    createInsertionNotificationSuccess,
} from "../api/events/Insertion";
import {
    createSeparationNotificationFailure,
    createSeparationNotificationSuccess,
} from "../api/events/Separation";
import {
    createGraftingNotificationFailure,
    createGraftingNotificationSuccess,
} from "../api/events/Grafting";
import {
    createInseminationNotificationFailure,
    createInseminationNotificationSuccess,
} from "../api/events/Insemination";
import {
    createTransferNotificationFailure,
    createTransferNotificationSuccess,
} from "../api/events/Transfer";
import {
    setFeedingEfficiencyNotificationFailure,
    setFeedingEfficiencyNotificationSuccess,
} from "../api/devices/efficiency";
import {
    setWaterEfficiencyNotificationFailure,
    setWaterEfficiencyNotificationSuccess,
} from "../api/devices/waterEfficiency";
import {
    createTreatmentNotificationFailure,
    createTreatmentNotificationSuccess,
} from "../api/events/Treatment";
import {
    removeProjectNotificationFailure,
    removeProjectNotificationSuccess,
} from "../api/projects/removeProject";
import {
    setNutriProEfficiencyNotificationFailure,
    setNutriProEfficiencyNotificationSuccess,
} from "../api/devices/nutriProEfficiency";
import {
    createTaggingNotificationSuccess,
    createTaggingNotificationFailure,
} from "../api/events/Tagging";
import { createSeparationToMommyNotificationFailure, createSeparationToMommyNotificationSuccess } from "../api/events/SeparationToMommy";
import { createUSGNotificationFailure, createUSGNotificationSuccess } from "../api/events/USG";
import { createNoPregnancyNotificationFailure, createNoPregnancyNotificationSuccess } from "../api/events/NoPregnancy";
import { createPigletsTreatmentNotificationFailure, createPigletsTreatmentNotificationSuccess } from "../api/events/PigletsTreatment";
import { createCastrationNotificationFailure, createCastrationNotificationSuccess } from "../api/events/Castration";
import { createFallPigletsNotificationFailure, createFallPigletsNotificationSuccess } from "../api/events/FallPiglets";
import { createFallNotificationFailure, createFallNotificationSuccess } from "../api/events/Fall";
import { createSellNotificationFailure, createSellNotificationSuccess } from "../api/events/Sell";
import { createHeatNotificationFailure, createHeatNotificationSuccess } from "../api/events/Heat";
import { createReclassifyNotificationFailure, createReclassifyNotificationSuccess } from "../api/events/Reclassify";
import { createWeightingNotificationFailure, createWeightingNotificationSuccess } from "../api/events/Weighting";
import { createIsMommyNotificationFailure, createIsMommyNotificationSuccess } from "../api/events/IsMommy";

function getNotification(type) {
    switch (type) {
        case "InsertionSuccess":
            return createInsertionNotificationSuccess;
        case "InsertionError":
            return createInsertionNotificationFailure;
        case "ParturitionSuccess":
            return createParturitionNotificationSuccess;
        case "ParturitionError":
            return createParturitionNotificationFailure;
        case "SeparationSuccess":
            return createSeparationNotificationSuccess;
        case "SeparationError":
            return createSeparationNotificationFailure;
        case "EfficiencySuccess":
            return setFeedingEfficiencyNotificationSuccess;
        case "EfficiencyError":
            return setFeedingEfficiencyNotificationFailure;
        case "WaterEfficiencySuccess":
            return setWaterEfficiencyNotificationSuccess;
        case "WaterEfficiencyError":
            return setWaterEfficiencyNotificationFailure;
        case "GraftingSuccess":
            return createGraftingNotificationSuccess;
        case "GraftingError":
            return createGraftingNotificationFailure;
        case "InseminationSuccess":
            return createInseminationNotificationSuccess;
        case "InseminationError":
            return createInseminationNotificationFailure;
        case "TransferSuccess":
            return createTransferNotificationSuccess;
        case "TransferError":
            return createTransferNotificationFailure;
        case "TreatmentSuccess":
            return createTreatmentNotificationSuccess;
        case "TreatmentError":
            return createTreatmentNotificationFailure;
        case "ProjectSuccess":
            return removeProjectNotificationSuccess;
        case "ProjectError":
            return removeProjectNotificationFailure;
        case "NutriProEfficiencySuccess":
            return setNutriProEfficiencyNotificationSuccess;
        case "NutriProEfficiencyError":
            return setNutriProEfficiencyNotificationFailure;
        case "TaggingSuccess":
            return createTaggingNotificationSuccess;
        case "TaggingError":
            return createTaggingNotificationFailure;
        case "SeparationToMommySuccess":
        case "SeparationToMommyGiltsSuccess":
            return createSeparationToMommyNotificationSuccess;
        case "SeparationToMommyError":
        case "SeparationToMommyGiltsError":
            return createSeparationToMommyNotificationFailure;
        case "UsgSuccess":
            return createUSGNotificationSuccess;
        case "UsgError":
            return createUSGNotificationFailure;
        case "NoPregnancySuccess":
            return createNoPregnancyNotificationSuccess;
        case "NoPregnancyError":
            return createNoPregnancyNotificationFailure;
        case "PigletsTreatmentSuccess":
            return createPigletsTreatmentNotificationSuccess;
        case "PigletsTreatmentError":
            return createPigletsTreatmentNotificationFailure;
        case "CastrationSuccess":
            return createCastrationNotificationSuccess;
        case "CastrationError":
            return createCastrationNotificationFailure;
        case "FallPigletsSuccess":
            return createFallPigletsNotificationSuccess;
        case "FallPigletsError":
            return createFallPigletsNotificationFailure;
        case "FallSuccess":
            return createFallNotificationSuccess;
        case "FallError":
            return createFallNotificationFailure;
        case "SellSuccess":
            return createSellNotificationSuccess;
        case "SellError":
            return createSellNotificationFailure;
        case "HeatSuccess":
            return createHeatNotificationSuccess;
        case "HeatError":
            return createHeatNotificationFailure;
        case "ReclassifySuccess":
        case "ReclassifySowSuccess":
            return createReclassifyNotificationSuccess;
        case "WeightingSuccess":
            return createWeightingNotificationSuccess;
        case "WeightingError":
            return createWeightingNotificationFailure;
        case "ReclassifyError":
        case "ReclassifySowError":
            return createReclassifyNotificationFailure;
        case "IsMommySuccess":
            return createIsMommyNotificationSuccess;
        case "IsMommyError":
            return createIsMommyNotificationFailure;
        default:
            console.log("not found");
    }
}

export function manageAsyncNotification(msg) {
    const {
        CData: { type, execID },
    } = msg;
    const state = store.getState();
    const { LocalUserID } = state.user.user;
    if (msg.CData.LocalUserID !== LocalUserID) return;
    getNotification(type)({}, { uniqueId: execID });
    const event = new Event("AsyncResponse");
    event.data = msg;
    document.dispatchEvent(event);
}
