import IoTResponseStatus from "@wesstron/utils/Api/constants/iotResponseStatus";
import i18next from "i18next";
import { cloneDeep, get, isArray, isObject, isString } from "lodash";
import pako from "pako";
import store from "../store/store";
import { bugsnagClient } from "./bugsnagUtils/BugsnagUtils";
import { deviceIdToName } from "./DevicesUtils";
import { snakeCaseToCamelCase } from "./TextUtils";

const _DEBUG = true;

/**
 * Funkcja przyjmująca cokolwiek* związanego z urządzeniem/ami i zwracająca odpowiednio sformatowane dane potrzebne przy wysyłaniu wiadomośći po MQTT
 * @param device: (Array<Object>, Array<String>, Object, String) - Lista/pojedyncze urządzenie lub jego ID
 * @param index
 * @returns {{LocalUserID: *, isValid: boolean, ClientID: *, GatewayID: *, DevID: Array, Name: string}}
 */
export function getIOTAttributes(device, index = null) {
    const state = store.getState();
    const LocalUserID = get(state, "user.user.LocalUserID");
    const ClientID = get(state, "user.user.ClientID");
    const devices = get(state, "farmDevices.devices");
    const getDeviceByID = (_id) => devices.find(d => d.DevID === _id);
    const getGwID = (obj) => obj?.IOT ? obj.IOT.name : obj.GatewayID;
    let DevID;
    let GatewayID;
    let name = '?';
    let isValid = false;
    _DEBUG && console.log("getIOTAttributes", device);
    try {
        //first check if our object is an instance of array
        if (isArray(device)) {
            _DEBUG && console.log("isArray", device);
            DevID = [];
            for (let element of device) {
                //check if object in array is a device or an ID
                if (isObject(element)) {
                    _DEBUG && console.log("isObject", element);
                    DevID.push(element.DevID);
                    if (!GatewayID) {
                        GatewayID = getGwID(element);
                    }
                    checkGateway(GatewayID, element.GatewayID);
                } else if (isString(element)) {
                    _DEBUG && console.log("isString", element);
                    let dev = getDeviceByID(element);
                    DevID.push(element);
                    if (dev) {
                        const gwID = getGwID(dev);
                        if (!GatewayID) {
                            GatewayID = gwID;
                        }
                        checkGateway(GatewayID, gwID);
                    }
                }
            }
        } else if (isObject(device)) {
            _DEBUG && console.log("isObject", device);
            DevID = device.DevID;
            GatewayID = getGwID(device);
        } else if (isString(device)) {
            _DEBUG && console.log("isString", device);
            let dev = getDeviceByID(device);
            if (dev) {
                DevID = dev.DevID;
                GatewayID = getGwID(dev);
            }
        }

        name = deviceIdToName(isArray(DevID) && DevID.length > 1 ? GatewayID : isArray(DevID) ? DevID[0] : DevID, index);
        if (isArray(DevID) && DevID.length === 1) {
            DevID = DevID[0];
        }
        if (isArray(DevID)) {
            name = `${name} (${DevID.length})`;
        }
        isValid = name && GatewayID && DevID && ClientID && LocalUserID;
    } catch (e) {
        console.error("getIOTAttributes EXCEPTION OCCURRED: ", e);
        isValid = false;
    }
    const result = {
        LocalUserID: LocalUserID,
        ClientID: ClientID,
        DevID: DevID,
        GatewayID: GatewayID,
        Name: name,
        isValid: !!isValid
    };
    if (!isValid) {
        const missingKeys = Object.keys(result).filter(key => !result[key]);
        console.error(`getIOTAttributes MISSING KEYS: ${missingKeys.join(", ")}`);
        try {
            sendBugsnagMissingAttributes(missingKeys, result);
            console.log("sending report");
        } catch (e) {
            console.error(e);
        }
    }
    return result;
}

function sendBugsnagMissingAttributes(missingKeys, ids) {
    const { user: { user: { ClientID } } } = store.getState();
    if (ClientID !== 'TestNewRole') {
        bugsnagClient.notify(new Error("missing IOT attributes"), (event) => {
            event.addMetadata("feedback", {
                user: ClientID,
                missing: missingKeys,
                ids: ids
            });
        });
    }
}

/**
 * Funkcja sprawdza czy kolejny Gateway przypisany do urzadzenie nie rozni sie od wczesniejszego a jesli sie rozni to zwraca blad
 * @param GatewayID
 * @param NextGatewayID
 */
export function checkGateway(GatewayID, NextGatewayID) {
    if (GatewayID && GatewayID !== NextGatewayID) {
        throw Error(`Device with different GatewayID passed! Expected: ${GatewayID} found: ${NextGatewayID}`);
    }
}

/**
 * Funkcja zamiania tablice/pojedynczy DevID na fejkowe DevID bez ktorego backend nam nie odpowie
 * @param DevID
 * @param type
 * @returns {*}
 */
export function transformDevID(DevID, type = "") {
    if (isArray(DevID)) {
        return DevID.map(devID => `${devID}${type}`);
    } else {
        return `${DevID}${type}`;
    }
}


export function removeTrash(obj) {
    let tmp = cloneDeep(obj);
    const trash = ["device", "SetTime", "loadedDataFrom", "changed"];
    trash.forEach(key => {
        delete tmp[key];
    });
    return tmp;


}

export function getTranslationKeyForResponseStatus(status) {
    switch (status) {
        case IoTResponseStatus.SUCCESS:
            return "success";
        case IoTResponseStatus.REQUEST_PARSER_ERROR:
            return "IOT.messageParserError";
        case IoTResponseStatus.COMMAND_REJECTED:
            return "IOT.deviceRejectedQuery";
        case IoTResponseStatus.NO_RESPONSE_FROM_DEVICE:
            return "IOT.noResponseFromDevice";
        case IoTResponseStatus.DATA_NOT_READY:
            return "IOT.noDownloadedDataOnTheDeviceWaitAMomentAndRepeatTheQuery";
        case IoTResponseStatus.RESPONSE_PARSER_ERROR:
            return "IOT.returningParserError";
        case IoTResponseStatus.NO_STATUS:
            return "IOT.responseProcessingError";
        case IoTResponseStatus.UNAUTHORIZED:
            return "IOT.noPrivileges";
        case IoTResponseStatus.DEVICE_CURRENTLY_FEEDING:
            return "IOT.alreadyFeeding";
        case IoTResponseStatus.DEVICE_OFFLINE:
            return "IOT.deviceOffline";
        default:
            for (let name in IoTResponseStatus) {
                if (!IoTResponseStatus.hasOwnProperty(name)) continue;
                const value = IoTResponseStatus[name];
                if (status === value) {
                    return `IOT.${snakeCaseToCamelCase(name)}`;
                }
            }
            // jeśli dostaliśmy status spoza listy to zwróć błąd generyczny
            return "IOT.noStatusRecognized";
    }
}

export function getMessageForStatus(status) {
    return i18next.t(getTranslationKeyForResponseStatus(status));
}


export function decompressData(data) {
    return JSON.parse(
        pako.ungzip(
            pako.inflate(
                Array.isArray(data)
                    ? data.reduce((prev, next) => prev + next, "")
                    : data
            ),
            {
                to: "string",
            }
        )
    );
}

export function compressData(data, level = 9) {
    try {
        return pako.deflate(pako.gzip(JSON.stringify(data), {
            level,
        }), {
            level,
            to: "string",
        });
    } catch (e) {
        console.error(`Error while compressing data: ${e}`);
    }
    return null;
}